export const monthsNames: any = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

export function formatDateMonthName(dateStr: string): string {
    if (Date.parse(dateStr)) {
        const date = new Date(dateStr)
        const month = monthsNames[date.getMonth()]
        return '' + date.getDate() + '-' + month + '-' + date.getFullYear()
    } else return ''
}

export function formatDateMonthNameOrToday(dateStr: string, widthTime = false): string {
    // Check today items
    const todayDate = new Date()
    todayDate.setHours(0 - todayDate.getTimezoneOffset() / 60) // Set today hours with timezone offset
    const dateFromStr = new Date(dateStr)

    // Set dateTime
    let dateTime = ''
    if (widthTime) {
        const hoursStr2 = ('0' + dateFromStr.getHours()).slice(-2)
        const minutesStr2 = ('0' + dateFromStr.getMinutes()).slice(-2)
        dateTime = ' ' + hoursStr2 + ':' + minutesStr2
    }

    if (dateFromStr.getFullYear() === todayDate.getUTCFullYear() &&
        dateFromStr.getMonth() === todayDate.getUTCMonth() &&
        dateFromStr.getDate() === todayDate.getUTCDate()) {
        return 'сегодня' + dateTime
    } else {
        return formatDateMonthName(dateStr) + dateTime
        // let curMonth = dateFromStr.getMonth()+1
        // return ''+ dateFromStr.getDate() +'/'+ ('0'+ curMonth).slice(-2)
    }
}

export function formatDateMonthOrToday(dateStr: string): string {
    // Check today items
    const todayDate = new Date()
    todayDate.setHours(0 - todayDate.getTimezoneOffset() / 60) // Set today hours with timezone offset
    const dateFromStr = new Date(dateStr)
    if (dateFromStr.getFullYear() === todayDate.getUTCFullYear() &&
        dateFromStr.getMonth() === todayDate.getUTCMonth() &&
        dateFromStr.getDate() === todayDate.getUTCDate()) {
        return 'сегодня'
    } else {
        const curMonth = dateFromStr.getMonth() + 1
        return '' + dateFromStr.getDate() + '/' + ('0' + curMonth).slice(-2)
    }
}