




















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";

import { createRequest } from "../../services/api-connector";
import {
  formatDateMonthName,
  formatDateMonthNameOrToday,
} from "../../utils/months-names";
import { getObjInAr, sortedArObjsDate } from "../../utils/utils";
import eventsRegister from "@/services/events-register";
import { leftArrowSVG, resultBoxSVG, resultErrorBoxSVG } from "@/services/svg";
import { opentParcelCargoCard } from "@/services/model";
import TransitionExpand from "../../components/TransitionExpand.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

// Transaction types pr488
// 11531 Оплата заказа на выкуп
// 11532   Возврат средств на баланс за отмененный заказ на выкуп
// 11533   Пополнение баланса
// 11534   Компенсация
// 11535   Оплата заказа доставки
const Stripe: any = window["Stripe"];
let stripe: any;
let cardNumber: any;
let setupIntent: any;

const elementStyles = {
  base: {
    color: "#282828",
    fontWeight: 300,
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSize: "16px",
    fontSmoothing: "antialiased",

    ":focus": { color: "#424770" },
    "::placeholder": { color: "#696969" },
    ":focus::placeholder": { color: "#696969" },
  },
  invalid: {
    color: "#FA755A",
    ":focus": {
      color: "#FA755A",
    },
    "::placeholder": {
      color: "#FFCCA5",
    },
  },
};

const elementClasses = {
  focus: "focus",
  empty: "empty",
  invalid: "invalid",
};

export default Vue.extend({
  components: { TransitionExpand },
  data() {
    return {
      eventsRegister,
      formatDateMonthName,
      formatDateMonthNameOrToday,
      getObjInAr,
      opentParcelCargoCard,
      leftArrowSVG,
      resultBoxSVG,
      resultErrorBoxSVG,

      activeForm: "savedCards", // or newCard

      isProcessing: false,

      savedCards: [] as any[],

      isBalanceHistoryOpen: false,
      isBalanceOpen: false,

      isPaySuccessVisible: false,
      isPayErrorVisible: false,
      isPayIntentLoading: false,

      isNewCardPuSlideActive: false,

      isArchiveLoaded: false,
    };
  },

  created() {
    this.setCards(this.cardList);
  },

  destroyed() {
    //@ts-ignore
    this.updateHelpData({ isPopupSlideActive: false });
    // cancel cancelSetupIntent (new card intent)
    if (setupIntent) {
      // get paymentId from clientSecret string pi_1H1qYuIXOKJSgckPrdSo3qut_asfgsfaaadgd34df
      createRequest(
        "cancelNewCardIntent",
        {
          // {"ClientCode": "2805956","StripeSetupIntent": "seti_1H584KIXOKJSgckPGm7NZveJ"}
          ClientCode: this.userId,
          StripeSetupIntent: setupIntent["id"],
        },
        () => {
          // Success callback function {"ResultCode": 0}
          setupIntent = null;
        },
        () => true // server error callback
      );
    }

    // Cleanup stripe - remove iframes
    // https://stackoverflow.com/questions/55119026/how-to-remove-stripes-iframes
    // https://github.com/stripe/react-stripe-elements/issues/99
    // document.querySelectorAll('[name^=__privateStripeMetricsController]'), // metrics have been added once on page loading
    // document.querySelectorAll('[name^=__privateStripeController]'), // iframes have been added many times on new payment form mounted
    // iframe.parentNode.removeChild(iframe);
    // let iframeEls: NodeList = document.querySelectorAll('[name^=__privateStripe]')
    const iframeEls: NodeList = document.querySelectorAll(
      "[name^=__privateStripeController]"
    );
    iframeEls.forEach((el: any) => {
      // HTMLIFrameElement
      el.remove();
    });
  },

  computed: {
    ...mapGetters("user", ["user", "userId", "moveMoneyList"]),
    ...mapGetters("cards", ["cardList", "isBankCardsLoaded"]),
    transactionsSorted(): any[] {
      return sortedArObjsDate(this.moveMoneyList, "pr250");
    },
  },

  methods: {
    ...mapMutations("cards", ["setCards"]),
    ...mapMutations("root", ["updateHelpData"]),
    ...mapActions("cards", ["loadSavedCards"]),
    ...mapActions("user", ["loadArchiveParcelList", "loadArchiveData"]),
    setCards(cards: any[]) {
      // set local props to Cards
      this.savedCards = cards.map((itemDB: any) => {
        if (cards.length === 1) itemDB.IsDefault = true;
        return { ...itemDB, curPosX: 0, isDeletedLocal: false, isAnim: false };
      });
      // check cards length. set default card if card only one
      if (this.savedCards.length === 1) this.savedCards[0].IsDefault = true;
    },

    checkActiveForm() {
      console.log(
        "!this.cardList.length",
        !this.cardList.length,
        this.activeForm
      );
      // check saved cards and create paymentIntent for new card
      if (!this.cardList.length) {
        // get setupIntent and create stripe form
        this.isPayIntentLoading = true;
        this.isPayErrorVisible = false;

        createRequest(
          "newCardIntent",
          { ClientCode: this.userId },
          (responseData: any) => {
            // Success callback function
            // {"stripe": {"setupIntent": {"id":"seti_1H584KIXOKJSgckPGm7NZveJ","clientSecret": "seti_1H584KIXOKJSgckPGm7NZveJ_secret_HeQwW7IqnLPBKb3zLWiJBn0qCves3w3", "publishableKey": "rk_live_bc7kMHHe1c61sIG3sRrjMtat00YV85NxQw"}}}
            if (responseData["stripe"]["setupIntent"]) {
              // https://stripe.com/docs/payments/save-and-reuse
              // setupElements - create stripe form
              setupIntent = responseData["stripe"]["setupIntent"];

              // const Stripe: any = window['Stripe']
              stripe = Stripe(setupIntent.publishableKey);
              const elements = stripe.elements();
              // let card = elements.create("card", { style: style })
              // card.mount("#card-element")

              cardNumber = elements.create("cardNumber", {
                showIcon: true, // https://stackoverflow.com/questions/42262887/enabling-brand-icon-in-cardnumber-type-element-in-stripe
                style: elementStyles,
                classes: elementClasses,
                placeholder: this.$t("cardNumberPuPay"),
              });
              cardNumber.mount("#input-card-number-finance");

              const cardExpiry = elements.create("cardExpiry", {
                style: elementStyles,
                classes: elementClasses,
                placeholder: this.$t("expireDatePuPay"),
              });
              cardExpiry.mount("#input-card-expiry-finance");

              const cardCvc = elements.create("cardCvc", {
                style: elementStyles,
                classes: elementClasses,
              });
              cardCvc.mount("#input-card-cvc-finance");
            } else this.isPayErrorVisible = true;

            this.isPayIntentLoading = false;
          },
          () => {
            // server error callback
            this.isPayErrorVisible = true;
            this.isPayIntentLoading = false;
          }
        );

        this.activeForm = "newCard";
      }
    },

    setBalanceHistory(isTrue: any) {
      this.isBalanceHistoryOpen = isTrue;
      this.updateHelpData({ isPopupSlideActive: isTrue });
    },

    setDefaultCardClicked(cardId: string) {
      // setDefautCardLocal(cardId)
      this.savedCards.forEach((card: any) => {
        if (card.CardId === cardId) card.IsDefault = true;
        else card.IsDefault = false;
      });

      createRequest(
        "defaultCard",
        {
          ClientCode: this.userId,
          StripePaymentMethodId: cardId,
        },
        (responseData: any) => {
          // success callback
          // {{"Cards": [...]}
          if ("Cards" in responseData) this.setCards(responseData.Cards);
          else console.warn("Error set default saved card");
        },
        () => true // error callback
      );
    },

    delCardClicked(cardId: any, cardIdx: number) {
      const activeCard: any = this.savedCards[cardIdx];
      activeCard["isDeletedLocal"] = true;
      createRequest(
        "deleteCard",
        {
          ClientCode: this.userId,
          StripePaymentMethodId: cardId,
        },
        (responseData: any) => {
          // success callback
          // {{"Cards": [...]}
          if ("Cards" in responseData) this.setCards(responseData.Cards);
          else console.warn("Error delete saved card");
        },
        () => true
      );
    },

    // onTouchStart(e: any, cardIdx: number) {
    //   clickStartPosX = unify(e).clientX;
    //   e = e || window.event;
    //   cardIdx = cardIdx;
    //   let d: any = document;
    //   d.addEventListener("mousemove", this.onTouchMove);
    //   d.addEventListener("mouseup", this.onTouchEnd);
    // },

    // onTouchEnd(e: any) {
    //   let d: any = document;
    //   d.removeEventListener("mousemove", this.onTouchMove);
    //   d.removeEventListener("mouseup", this.onTouchEnd);
    //   let activeCardSlide: any = this.savedCards[cardIdx];
    //   if (activeCardSlide.curPosX < -80) {
    //     // set max left pos on small swipe
    //     activeCardSlide.isAnim = true;
    //     activeCardSlide.curPosX = -160;
    //   } else {
    //     // reset state on small swipe
    //     activeCardSlide.isAnim = true;
    //     activeCardSlide.curPosX = 0;
    //   }
    // },

    // onTouchMove(e: any) {
    //   let activeCardSlide: any = this.savedCards[cardIdx];
    //   if (activeCardSlide.curPosX <= -160) return false; // ignore overswipe
    //   let scrollEl = e.target;
    //   curMoveX = unify(e).clientX - clickStartPosX;
    //   if (curMoveX < 0 && curMoveX >= -160)
    //     // left swipe
    //     activeCardSlide.curPosX = curMoveX;
    //   // scrollEl.style.transform ='translateX('+ curMoveX +'px)'
    // },

    transactionClicked(transItem: any) {
      // 11531   Оплата заказа на выкуп  10050 - Тип денежной операции
      // 11532   Возврат средств на баланс за отмененный заказ   10050 - Тип денежной операции
      // 11533   Пополнение баланса  10050 - Тип денежной операции
      // 11534   Компенсация 10050 - Тип денежной операции
      // 11535   Оплата заказа доставки  10050 - Тип денежной операции
      // 11584   Бонусы и акции  10050 - Тип денежной операции
      if (transItem.pr488 === 11535 && transItem.sKey === "cargos")
        opentParcelCargoCard(this.$root, transItem.Order_id);
      else if ([11535, 11532, 11531].includes(transItem.pr488))
        this.$router.push({
          name: "ParcelInfo",
          params: { parcelId: transItem.Order_id },
        });
    },

    formatDateMonthNameLang(dateStr: string): string {
      if (Date.parse(dateStr)) {
        const date = new Date(dateStr);
        const monthKey = "month" + (date.getMonth() + 1); // from month1 to month12
        const month = this.$t(monthKey);
        return "" + date.getDate() + " " + month + " " + date.getFullYear();
      } else return "";
    },

    openNewCardSlidePu() {
      // WARN: small memory leak possible. Each time when stripe form created, stripe add iframe. This iframes removed only when page destroyed
      // this.activeForm = 'newCard'
      this.updateHelpData({ isPopupSlideActive: true });
      this.isNewCardPuSlideActive = true;
      if (setupIntent) this.createNewCardForm();
      else {
        // get stripe payment intent
        this.isPayIntentLoading = true;
        this.isPayErrorVisible = false;
        createRequest(
          "newCardIntent",
          { ClientCode: this.userId },
          (responseData: any) => {
            // Success callback function
            // {"stripe": {"setupIntent": {"id":"seti_1H584KIXOKJSgckPGm7NZveJ","clientSecret": "seti_1H584KIXOKJSgckPGm7NZveJ_secret_HeQwW7IqnLPBKb3zLWiJBn0qCves3w3", "publishableKey": "rk_live_bc7kMHHe1c61sIG3sRrjMtat00YV85NxQw"}}}
            if (responseData["stripe"]["setupIntent"]) {
              // https://stripe.com/docs/payments/save-and-reuse
              // setupElements - create stripe form
              setupIntent = responseData["stripe"]["setupIntent"];

              this.createNewCardForm();
            } else this.isPayErrorVisible = true;

            this.isPayIntentLoading = false;
          },
          () => {
            // server error callback
            this.isPayErrorVisible = true;
            this.isPayIntentLoading = false;
          }
        );
      }
    },

    createNewCardForm() {
      // const Stripe: any = window['Stripe']
      stripe = Stripe(setupIntent.publishableKey);
      const elements = stripe.elements();
      // let card = elements.create("card", { style: style })
      // card.mount("#card-element")

      cardNumber = elements.create("cardNumber", {
        showIcon: true, // https://stackoverflow.com/questions/42262887/enabling-brand-icon-in-cardnumber-type-element-in-stripe
        style: elementStyles,
        classes: elementClasses,
        placeholder: this.$t("cardNumberPuPay"),
      });
      cardNumber.mount("#input-card-number-finance-slide");

      const cardExpiry = elements.create("cardExpiry", {
        style: elementStyles,
        classes: elementClasses,
        placeholder: this.$t("expireDatePuPay"),
      });
      cardExpiry.mount("#input-card-expiry-finance-slide");

      const cardCvc = elements.create("cardCvc", {
        style: elementStyles,
        classes: elementClasses,
      });
      cardCvc.mount("#input-card-cvc-finance-slide");
    },

    saveCard() {
      this.isPayErrorVisible = false;
      this.isPaySuccessVisible = false;
      this.isProcessing = true;
      stripe
        .confirmCardSetup(setupIntent["clientSecret"], {
          payment_method: { card: cardNumber },
        })
        .then((result: any) => {
          if (result.error) {
            // Display error.message in your UI.
            console.log("Error save new card: " + result.error.message);
            this.$root.$emit(eventsRegister.addMessage, {
              type: "error",
              message: this.$t("addCardErrorPayPu"),
            });
            this.isProcessing = false;
          } else {
            // The setup has succeeded. Display a success message
            // this.$root.$emit( eventsRegister.updateSavedCards )
            //@ts-ignore
            this.loadSavedCards().then(() => {
              this.isProcessing = false;
              this.$root.$emit(eventsRegister.addMessage, {
                type: "success",
                message: this.$t("addCardSuccessPayPu"),
              });
              setupIntent = null;
              this.activeForm = "savedCards";
            });
          }
        });
    },

    savedCardSlide() {
      this.isPaySuccessVisible = false;
      this.isPayErrorVisible = false;
      this.isProcessing = true;
      stripe
        .confirmCardSetup(setupIntent["clientSecret"], {
          payment_method: { card: cardNumber },
        })
        .then((result: any) => {
          if (result.error) {
            // Display error.message in your UI.
            console.log("Error save new card: " + result.error.message);
            this.$root.$emit(eventsRegister.addMessage, {
              type: "error",
              message: this.$t("addCardErrorPayPu"),
            });
            this.isProcessing = false;
          } else {
            // The setup has succeeded. Display a success message.
            //@ts-ignore
            this.loadSavedCards().then(() => {
              this.isProcessing = false;
              this.$root.$emit(eventsRegister.addMessage, {
                type: "success",
                message: this.$t("addCardSuccessPayPu"),
              });
              setupIntent = null;
              this.activeForm = "savedCards";
              this.isNewCardPuSlideActive = false;
            });
          }
        });
    },

    async loadArchive() {
      this.isProcessing = true;
      //@ts-ignore
      await Promise.all([this.loadArchiveParcelList(), this.loadArchiveData()]);
      this.isArchiveLoaded = true;
      this.isProcessing = false;
    },
  },
  watch: {
    cardList() {
      this.setCards(this.cardList); // set cards once. non reactive data
      this.checkActiveForm();
    },
  },
});
